export enum GtmEventType {
  Contact = 'Contact',
  Clicker = 'Clicker',
  Blog = 'Blog',
  Sharing = 'Sharing',
  Youtube = 'Youtube',
  Associations = 'Associations',
  FAQ = 'FAQ',
  Downloads = 'Downloads',
  Shops = 'Shops',
  Category = 'Category',
  Trooperbonnen = 'Trooperbonnen',
  Share = 'Share',
  ProductClick = 'productClick',
  Checkout = 'checkout',
  ProductFilters = 'product-filters',
  Impressions = 'eec.impressionView',
  Details = 'eec.ProductDetail',
  Purchase = 'purchase',
  FormSubmission = 'FormSubmission',
  ClickerCampaign = 'clicker_campaign',
  AddToNewsletter = 'AddToNewsletter',
  ClickerCoupon = 'clicker_coupon',
  ShopButtonType = 'shopbuttontype',
  SearchAssociations = 'searchAssociations',
  Landing = 'landing',
  TransactionalSearch = 'TransactionalSearch',
  ProductSearchSorting = 'ProductSearchSorting',
  ProductSearchFilter = 'ProductSearchFilter',
}
