import { type GtmEventType } from '../types/enums/gtm-event-type.enum';

export class GtmHelper {
  static pushToGtm(gtmEventType: GtmEventType, data: any) {
    if (!(window as any).dataLayer) return;
    let pushData = data;

    if (gtmEventType) {
      const eventData = {
        event: gtmEventType,
      };
      pushData = { ...eventData, ...data };
    }

    (window as any).dataLayer.push(pushData);
  }
}
