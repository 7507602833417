import * as React from 'react';
import { LANG_CODES } from '../../../constants';

interface AlternateLinksProps {
  nlPath: string
  frPath: string
}

const AlternateLinks: React.FC<AlternateLinksProps> = function ({ nlPath, frPath }) {
  return (
    <>
      <link rel="alternate" hrefLang={LANG_CODES.NL} href={`${process.env.GATSBY_SITE_URL}/nl/${nlPath}`} />
      <link rel="alternate" hrefLang={LANG_CODES.FR} href={`${process.env.GATSBY_SITE_URL}/fr/${frPath}`} />
    </>
  );
};

export default AlternateLinks;
