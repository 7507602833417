import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { currencyFormat } from '../../../utils/currency-utils';
import { type AlgoliaAssociation } from '../../../types/algolia.model';
import { Language } from '../../../types/enums/language.enum';
import * as styles from './association-item.module.scss';

interface AssociationItemProps {
  association: AlgoliaAssociation
  showEarning: boolean
}

function AssociationItem({ association, showEarning }: AssociationItemProps) {
  const { language } = useI18next();

  return (
    <div className={styles.container}>
      <div className={styles.border}>
        <img alt={association.name} className={styles.logo} src={association.logoUrl} />
        <div className={styles.content}>
          <div className={styles.data}>
            <div className={styles.name}>{association.name}</div>
            <div className={styles.description}>{language === Language.NL ? association.cityNameNL : association.cityNameFR}</div>
          </div>
          {showEarning
            ? (
              <div className={styles.earning}>
                <div>{currencyFormat.format(association.totalSales)}</div>
              </div>
            )
            : null}
        </div>
      </div>
    </div>

  );
}

export default AssociationItem;
