export class Gtm {
  eventAction: string;

  eventLabel: string | null;

  constructor(action: string, label: string | null = null) {
    this.eventAction = action;
    this.eventLabel = label;
  }
}
