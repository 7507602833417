import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { SearchBar } from '../../devlink/SearchBar';
import { SearchBarFr } from '../../devlink/SearchBarFr';
import { Language } from '../../types/enums/language.enum';

function SearchBanner() {
  const { language } = useI18next();

  return (
    <>
      {language === Language.NL ? <SearchBar /> : <SearchBarFr />}
    </>
  );
}

export default SearchBanner;
