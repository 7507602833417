import * as React from 'react';
import {
  graphql, navigate, type PageProps, Script,
} from 'gatsby';
import { useEffect, useState } from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { clsx } from 'clsx';
import Layout from '../../components/layout';
import AssociationNoResults from '../../components/associations/association-no-results/association-no-results';
import AssociationItem from '../../components/associations/association-item/association-item';
import SpinnerDots from '../../components/spinner-dots/spinner-dots';
import { GtmEventType } from '../../types/enums/gtm-event-type.enum';
import { Gtm } from '../../types/gtm.model';
import { GtmHelper } from '../../utils/gtm-utils';
import SearchBanner from '../../components/search-banner/search-banner';
import { TrooperQueryParam } from '../../utils/query-params-const';
import SEO from '../../components/shared/seo/seo';
import { type AlgoliaAssociation } from '../../types/algolia.model';
import AlternateLinks from '../../components/alternate-links/alternate-links';
import { TROOPER_PATHS } from '../../../constants';
import * as styles from './association-search-results.module.scss';
import { getOppositeLanguage } from '../../utils/i18n-utils';

const fetchAssociations = async (
  searchTerm: string,
  page: number,
  pageSize: number,
) => {
  const urlIndexPart = `${process.env.GATSBY_ALGOLIA_QUERY_URL!
    .replace('{indexName}', process.env.GATSBY_ALGOLIA_ASSOCIATIONS_INDEX!)}`;
  const url = `${process.env.GATSBY_ALGOLIA_BASE_URL}${urlIndexPart}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Algolia-API-Key': process.env.GATSBY_ALGOLIA_API_KEY!,
      'X-Algolia-Application-Id': process.env.GATSBY_ALGOLIA_APPLICATION_ID!,
    },
    body: JSON.stringify({
      query: searchTerm,
      hitsPerPage: pageSize,
      page: page - 1,
      responseFields: ['hits'],
    }, (_, v) => v ?? undefined),
  })
    .then(async (response) => response.json())
    .then((response) => {
      if (response?.hits) {
        return response.hits;
      }
      return [];
    });
};

function AssociationSearchResultPage({ location }: PageProps) {
  const params = new URLSearchParams(location.search);
  const searchTerm = params.get(TrooperQueryParam.AssociationSearchTerm);
  const pageSize = 12;

  const [associations, setAssociations] = useState([] as AlgoliaAssociation[]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const { language, t } = useI18next();
  const oppositeLanguage = getOppositeLanguage(language);
  const otherLanguagePath = `/${oppositeLanguage}/${TROOPER_PATHS[oppositeLanguage].association}`;

  const handleLoadMore = () => {
    setIsLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    fetchAssociations(searchTerm!, newPage, pageSize).then((newAssociations) => {
      setAssociations((arr) => arr.concat(newAssociations));
      setIsLoading(false);
      setCanLoadMore(newAssociations.length === pageSize);
    });
  };

  const onAssociationClick = (pageName: string) => {
    GtmHelper.pushToGtm(
      GtmEventType.Associations,
      new Gtm('Click', pageName),
    );
    navigate(pageName);
  };

  const sendLandingEventToGtm = () => {
    const translation = t('Metadescriptions_Associations_Google_Title');

    GtmHelper.pushToGtm(GtmEventType.Landing, {
      pageType: translation,
    });
  };

  useEffect(() => {
    sendLandingEventToGtm();
    fetchAssociations(searchTerm || '', page, pageSize)
      .then((newAssociations) => {
        setAssociations(newAssociations);
        setIsLoading(false);
        setCanLoadMore(newAssociations.length === pageSize);
      });
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, pageName: string) => {
    if (event.key === 'Enter') {
      onAssociationClick(pageName);
    }
  };

  return (
    <Layout otherLanguagePath={otherLanguagePath}>
      <Script id="trooper-web-components" src={process.env.GATSBY_WEB_COMPONENTS_URL} />
      <SearchBanner />
      <div className={clsx(styles.container, styles.main)}>
        <div className={styles.associationsContainer}>
          {!isLoading && associations.length === 0 ? <AssociationNoResults /> : null}
          {associations.map((association: AlgoliaAssociation) => (
            <div
              className={styles.association}
              key={association.objectID}
              onClick={() => { onAssociationClick(association.pageName); }}
              onKeyDown={(event) => handleKeyDown(event, association.pageName)}
              role="button"
              tabIndex={0}
              aria-label={association.name}
            >
              <AssociationItem
                association={association}
                showEarning
              />
            </div>
          ))}
        </div>
        <div className={styles.loadMore}>
          {
              !isLoading && canLoadMore
              && (
              <button type="button" className="btn btn--orange" onClick={handleLoadMore}>
                <Trans>Associations_LoadMore</Trans>
              </button>
              )
          }
          <SpinnerDots isLoading={isLoading} showBackdrop={false} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
    query AssociationSearchResultPage($language: String!) {
        locales: allLocale(filter: {ns: {in: [
          "Associations",
          "Metadescriptions_Associations_Google",
          "Metadescriptions_Associations_Og",
          "Association_NoResults"
        ]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export function Head({ data, location }: PageProps<Queries.AssociationSearchResultPageQuery>) {
  // https://github.com/gatsbyjs/gatsby/issues/36458

  const dataLanguage = data.locales.edges
    .filter((e) => e.node.ns === 'Metadescriptions_Associations_Google'
          || e.node.ns === 'Metadescriptions_Associations_Og')
    .map((n) => n.node.data);

  const t = {
    // @ts-expect-error
    ...JSON.parse(dataLanguage[0]),
    // @ts-expect-error
    ...JSON.parse(dataLanguage[1]),
  };

  return (
    <SEO
      image="https://trooperv2.blob.core.windows.net/staticassets/og_trooperverenigingen.jpg"
      title={t.Metadescriptions_Associations_Google_Title}
      ogTitle={t.Metadescriptions_Associations_Og_Title}
      description={t.Metadescriptions_Associations_Google_Description}
      ogDescription={t.Metadescriptions_Associations_Og_Description}
      pathname={location.pathname}
    >
      <AlternateLinks nlPath={TROOPER_PATHS.nl.association} frPath={TROOPER_PATHS.fr.association} />
    </SEO>
  );
}
export default AssociationSearchResultPage;
